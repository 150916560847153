var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CModal',{attrs:{"title":("Trả góp đơn hàng #" + (_vm.installment ? _vm.installment.Order.OrderNumber : '')),"show":_vm.detailModal,"closeOnBackdrop":false,"centered":"","size":"xl"},on:{"update:show":function($event){_vm.detailModal=$event}},scopedSlots:_vm._u([(_vm.installment)?{key:"body-wrapper",fn:function(){return [_c('div',{staticClass:"modal-body overflow-auto",staticStyle:{"max-height":"calc(100vh - 12.1rem)"}},[_c('CRow',[_c('CCol',{attrs:{"md":"4"}},[_c('CCard',[_c('CCardHeader',[_c('CIcon',{attrs:{"name":"cil-calculator"}}),_vm._v(" Nội dung đăng ký trả góp ")],1),_c('CCardBody',[_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"col-6"},[_vm._v(" Đơn hàng: ")]),_c('div',{staticClass:"col-6"},[_c('router-link',{attrs:{"to":{
                      name: 'OrderList',
                      params: { orderId: _vm.installment.Order.Id },
                    },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var href = ref.href;
                    var navigate = ref.navigate;
return [_c('a',{attrs:{"href":href},on:{"click":navigate}},[_vm._v(_vm._s(_vm.installment.Order.OrderNumber))])]}}],null,false,3607141355)})],1)]),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"col-6"},[_vm._v(" Tổng tiền: ")]),_c('div',{staticClass:"col-6"},[_vm._v(" "+_vm._s(_vm.$func.addCommas(_vm.installment.Total))+" ")])]),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"col-6"},[_vm._v(" Thời gian: ")]),_c('div',{staticClass:"col-6"},[_vm._v(_vm._s(_vm.installment.Months)+" tháng")])]),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"col-6"},[_vm._v(" Tiền hàng tháng: ")]),_c('div',{staticClass:"col-6"},[_vm._v(" "+_vm._s(_vm.$func.addCommas(_vm.installment.PricePer))+" ")])]),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"col-6"},[_vm._v(" Ngày bắt đầu: ")]),_c('div',{staticClass:"col-6"},[_vm._v(" "+_vm._s(_vm.$moment .utc(_vm.installment.StartDate) .local() .format("DD/MM/YYYY"))+" ")])]),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"col-6"},[_vm._v(" Dư nợ hiện tại: ")]),_c('div',{staticClass:"col-6"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$func.addCommas(_vm.installment.Remaining))+" ")])])]),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"col-6"},[_vm._v(" Trạng thái: ")]),_c('div',{staticClass:"col-6"},[_c('CBadge',{attrs:{"color":_vm.getBadge(_vm.installment.Status)}},[_vm._v(" "+_vm._s(_vm.$const.INSTALLMENT_STATUS_TEXT[_vm.installment.Status])+" ")])],1)]),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"col-12 border-top mt-2 pt-2 font-italic"},[_c('CIcon',{attrs:{"name":"cil-location-pin"}}),_vm._v(" "+_vm._s(_vm.renderAddress(_vm.installment.Order.BillAddress))+" ")],1)])])],1)],1),_c('CCol',{attrs:{"md":"8"}},[_c('CCard',[_c('CCardHeader',[_c('CIcon',{attrs:{"name":"cil-dollar"}}),_vm._v(" Chi tiết thanh toán ")],1),_c('CCardBody',[(
                  _vm.installment.Status == _vm.$const.INSTALLMENT_STATUS.InProgress
                )?_c('div',{staticClass:"form-row"},[_c('CCol',{attrs:{"md":"5"}},[_c('div',{staticClass:"form-group form-row",attrs:{"role":"group"}},[_c('label',{staticClass:"col-form-label col-4 text-right"},[_vm._v(" Tiền trả góp ")]),_c('div',{staticClass:"col-8"},[_c('money',{staticClass:"form-control text-right",class:{
                          'is-invalid': !!_vm.inValidObject['paymentAmount'],
                        },attrs:{"placeholder":"Tiền trả góp","value":_vm.paymentAmount},on:{"input":function (e) {
                            _vm.paymentAmount = e
                              ? parseFloat(e) >= 0
                                ? parseFloat(e)
                                : 0
                              : 0;
                          }}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.inValidObject["paymentAmount"])+" ")])],1)])]),_c('CCol',{attrs:{"md":"5"}},[_c('div',{staticClass:"form-group form-row"},[_c('label',{staticClass:"col-4 col-form-label text-right"},[_vm._v(" Thời gian ")]),_c('div',{staticClass:"col-8"},[_c('datetime',{class:{
                          'is-invalid': !!_vm.inValidObject['paymentDate'],
                        },attrs:{"type":"datetime","format":"dd/MM/yyyy HH:mm","input-class":{
                          'form-control': true,
                          'is-invalid': !!_vm.inValidObject['paymentDate'],
                        }},model:{value:(_vm.paymentDate),callback:function ($$v) {_vm.paymentDate=$$v},expression:"paymentDate"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.inValidObject["paymentDate"])+" ")])],1)])]),_c('CCol',{attrs:{"md":"2"}},[(_vm.saving)?_c('div',{staticClass:"d-inline-block font-italic"},[_c('CSpinner',{attrs:{"color":"info","size":"sm"}})],1):_c('CButton',{staticClass:"btn btn-primary",on:{"click":_vm.saveInstallment}},[_c('CIcon',{attrs:{"name":"cil-save","custom-classes":"c-icon m-0"}}),_vm._v(" Cập nhật ")],1)],1)],1):_vm._e(),_c('div',{staticClass:"table-responsive-sm"},[_c('table',{staticClass:"table table-sm table-striped"},[_c('thead',[_c('tr',[_c('th',[_vm._v("STT")]),_c('th',[_vm._v("Nội dung")]),_c('th',[_vm._v("Số tiền")]),_c('th',[_vm._v("Thời gian")])])]),_c('tbody',[(
                        _vm.installment.Order.OrderPayments &&
                          _vm.installment.Order.OrderPayments.length
                      )?_vm._l((_vm.installment.Order.OrderPayments),function(item,i){return _c('tr',{key:("payment-" + (item.Id))},[_c('td',[_vm._v(_vm._s(i + 1)+".")]),_c('td',[_vm._v(" "+_vm._s(_vm.$const.PAYMENT_TYPES_TEXT[item.PaymentType])+" ")]),_c('td',[_c('span',{class:{
                              'text-success': item.Amount > 0,
                              'text-danger': item.Amount < 0,
                              'text-warning': !item.Amount,
                            }},[_vm._v(" "+_vm._s(_vm.$func.addCommas(item.Amount))+" ")])]),_c('td',[_vm._v(" "+_vm._s(_vm.$moment .utc(item.CreatedAt) .local() .format("DD/MM/YYYY HH:mm"))+" ")])])}):_c('tr',[_c('td',{attrs:{"colspan":"100%"}},[_vm._v(" Chưa có thanh toán nào ")])])],2),_c('tfoot',[_c('tr',[_c('th',{staticClass:"text-center",attrs:{"colspan":"100%"}},[_vm._v(" Tổng tiền: "+_vm._s(_vm.$func.addCommas(_vm.totalIncome))+" ")])])])])])])],1)],1)],1)],1)]},proxy:true}:null,{key:"footer-wrapper",fn:function(){return [_c('div')]},proxy:true}],null,true)},[(_vm.loading)?_c('div',{staticClass:"spinner-border m-5",attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Loading...")])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }