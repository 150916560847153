<template>
  <CModal
    :title="
      `Trả góp đơn hàng #${installment ? installment.Order.OrderNumber : ''}`
    "
    :show.sync="detailModal"
    :closeOnBackdrop="false"
    centered
    size="xl"
  >
    <div v-if="loading" class="spinner-border m-5" role="status">
      <span class="sr-only">Loading...</span>
    </div>
    <template v-if="installment" v-slot:body-wrapper>
      <div
        class="modal-body overflow-auto"
        style="max-height:calc(100vh - 12.1rem)"
      >
        <CRow>
          <CCol md="4">
            <CCard>
              <CCardHeader>
                <CIcon name="cil-calculator" />
                Nội dung đăng ký trả góp
              </CCardHeader>
              <CCardBody>
                <div class="form-row">
                  <div class="col-6">
                    Đơn hàng:
                  </div>
                  <div class="col-6">
                    <router-link
                      :to="{
                        name: 'OrderList',
                        params: { orderId: installment.Order.Id },
                      }"
                      custom
                      v-slot="{ href, navigate }"
                    >
                      <a :href="href" @click="navigate">{{
                        installment.Order.OrderNumber
                      }}</a>
                    </router-link>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-6">
                    Tổng tiền:
                  </div>
                  <div class="col-6">
                    {{ $func.addCommas(installment.Total) }}
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-6">
                    Thời gian:
                  </div>
                  <div class="col-6">{{ installment.Months }} tháng</div>
                </div>
                <div class="form-row">
                  <div class="col-6">
                    Tiền hàng tháng:
                  </div>
                  <div class="col-6">
                    {{ $func.addCommas(installment.PricePer) }}
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-6">
                    Ngày bắt đầu:
                  </div>
                  <div class="col-6">
                    {{
                      $moment
                        .utc(installment.StartDate)
                        .local()
                        .format("DD/MM/YYYY")
                    }}
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-6">
                    Dư nợ hiện tại:
                  </div>
                  <div class="col-6">
                    <span class="font-weight-bold">
                      {{ $func.addCommas(installment.Remaining) }}
                    </span>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-6">
                    Trạng thái:
                  </div>
                  <div class="col-6">
                    <CBadge :color="getBadge(installment.Status)">
                      {{ $const.INSTALLMENT_STATUS_TEXT[installment.Status] }}
                    </CBadge>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-12 border-top mt-2 pt-2 font-italic">
                    <CIcon name="cil-location-pin" />
                    {{ renderAddress(installment.Order.BillAddress) }}
                  </div>
                </div>
              </CCardBody>
            </CCard>
          </CCol>
          <CCol md="8">
            <CCard>
              <CCardHeader>
                <CIcon name="cil-dollar" />
                Chi tiết thanh toán
              </CCardHeader>
              <CCardBody>
                <div
                  class="form-row"
                  v-if="
                    installment.Status == $const.INSTALLMENT_STATUS.InProgress
                  "
                >
                  <CCol md="5">
                    <div role="group" class="form-group form-row">
                      <label class="col-form-label col-4 text-right">
                        Tiền trả góp
                      </label>
                      <div class="col-8">
                        <money
                          placeholder="Tiền trả góp"
                          :value="paymentAmount"
                          class="form-control text-right"
                          :class="{
                            'is-invalid': !!inValidObject['paymentAmount'],
                          }"
                          @input="
                            (e) => {
                              paymentAmount = e
                                ? parseFloat(e) >= 0
                                  ? parseFloat(e)
                                  : 0
                                : 0;
                            }
                          "
                        ></money>
                        <div class="invalid-feedback">
                          {{ inValidObject["paymentAmount"] }}
                        </div>
                      </div>
                    </div>
                  </CCol>
                  <CCol md="5">
                    <div class="form-group form-row">
                      <label class="col-4 col-form-label text-right">
                        Thời gian
                      </label>
                      <div class="col-8">
                        <datetime
                          type="datetime"
                          v-model="paymentDate"
                          format="dd/MM/yyyy HH:mm"
                          :input-class="{
                            'form-control': true,
                            'is-invalid': !!inValidObject['paymentDate'],
                          }"
                          :class="{
                            'is-invalid': !!inValidObject['paymentDate'],
                          }"
                        />
                        <div class="invalid-feedback">
                          {{ inValidObject["paymentDate"] }}
                        </div>
                      </div>
                    </div>
                  </CCol>
                  <CCol md="2">
                    <div class="d-inline-block font-italic" v-if="saving">
                      <CSpinner color="info" size="sm" />
                    </div>
                    <CButton
                      v-else
                      class="btn btn-primary"
                      @click="saveInstallment"
                    >
                      <CIcon name="cil-save" custom-classes="c-icon m-0" />
                      Cập nhật
                    </CButton>
                  </CCol>
                </div>
                <div class="table-responsive-sm">
                  <table class="table table-sm table-striped">
                    <thead>
                      <tr>
                        <th>STT</th>
                        <th>Nội dung</th>
                        <th>Số tiền</th>
                        <th>Thời gian</th>
                      </tr>
                    </thead>
                    <tbody>
                      <template
                        v-if="
                          installment.Order.OrderPayments &&
                            installment.Order.OrderPayments.length
                        "
                      >
                        <tr
                          v-for="(item, i) in installment.Order.OrderPayments"
                          :key="`payment-${item.Id}`"
                        >
                          <td>{{ i + 1 }}.</td>
                          <td>
                            {{ $const.PAYMENT_TYPES_TEXT[item.PaymentType] }}
                          </td>
                          <td>
                            <span
                              :class="{
                                'text-success': item.Amount > 0,
                                'text-danger': item.Amount < 0,
                                'text-warning': !item.Amount,
                              }"
                            >
                              {{ $func.addCommas(item.Amount) }}
                            </span>
                          </td>
                          <td>
                            {{
                              $moment
                                .utc(item.CreatedAt)
                                .local()
                                .format("DD/MM/YYYY HH:mm")
                            }}
                          </td>
                        </tr>
                      </template>
                      <tr v-else>
                        <td colspan="100%">
                          Chưa có thanh toán nào
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <th colspan="100%" class="text-center">
                          Tổng tiền: {{ $func.addCommas(totalIncome) }}
                        </th>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </div>
    </template>
    <template v-slot:footer-wrapper><div /></template>
  </CModal>
</template>

<script>
import { Datetime } from "vue-datetime";
// You need a specific loader for CSS files
import "vue-datetime/dist/vue-datetime.css";

export default {
  props: ["editing", "installmentId"],
  components: {
    Datetime,
  },
  data() {
    return {
      detailModal: false,
      inValidObject: {},
      installment: null,
      loading: false,
      saving: false,
      paymentDate: null,
      paymentAmount: 0,
    };
  },
  computed: {
    totalIncome() {
      let payments = this.installment.Order
        ? this.installment.Order.OrderPayments
        : null;
      if (payments && payments.length) {
        return payments.reduce((acc, cur) => acc + cur.Amount, 0);
      }
      return 0;
    },
  },
  watch: {
    async installmentId(val) {
      this.loading = true;
      this.installment = null;
      this.installment = await this.loadInstallmentInfo(val);
      this.paymentAmount = 0;
      this.paymentDate = null;
      this.loading = false;
    },
    editing(val) {
      this.detailModal = val;
      if (!val) {
        this.$emit("update:installmentId", null);
      }
    },
    detailModal(val) {
      this.$emit("update:editing", val);
    },
  },
  methods: {
    async loadInstallmentInfo(installmentId) {
      try {
        if (installmentId) {
          let filters = [];
          installmentId && filters.push(`Id eq ${installmentId}`);
          let resp = await this.$http.get(`odata/OrderInstallment`, {
            params: {
              $top: 1,
              $filter: filters.join(" and "),
              $expand: `Order($expand=Customer,OrderPayments($orderby=CreatedAt;$filter=Status eq ${this.$const.STATUS.Active}))`,
            },
          });
          if (resp && resp.status == 200) {
            return resp.data.value[0];
          }
        }
      } catch (error) {
        alert(error);
      }
      return null;
    },
    getBadge(status) {
      switch (status) {
        case this.$const.INSTALLMENT_STATUS.Completed:
          return "success";
        case this.$const.INSTALLMENT_STATUS.InProgress:
          return "warning";
        case this.$const.INSTALLMENT_STATUS.Cancelled:
          return "secondary";
        default:
          "primary";
      }
    },

    renderAddress(address) {
      if (address) {
        let addrObj = JSON.parse(address);
        return (
          addrObj.Title +
          " " +
          Object.keys(addrObj)
            .filter((_) => _ != "Title")
            .map((_) => addrObj[_])
            .filter((_) => _)
            .join(", ")
        );
      }
      return "";
    },

    async saveInstallment() {
      let installment = this.installment;
      let paymentAmount = this.paymentAmount;
      let paymentDate = this.paymentDate;
      this.inValidObject = this.checkValid(
        installment,
        paymentAmount,
        paymentDate
      );

      if (Object.keys(this.inValidObject).length) {
        alert("Thông tin nhập chưa đúng, vui lòng kiểm tra lại!");
        return;
      }

      this.saving = true;
      try {
        let resp = await this.$http.post(`Order/InstallmentPayment`, {
          InstallmentId: installment.Id,
          Amount: paymentAmount,
          PaymentDate: paymentDate,
        });
        if (resp && resp.status == 200) {
          if (resp.data.status == this.$const.RESPONSE_TYPES.Success) {
            this.paymentAmount = 0;
            this.paymentDate = null;
            this.installment = null;
            this.installment = await this.loadInstallmentInfo(installment.Id);
            this.$emit("reload");
          }
          if (resp.data.message) {
            alert(this.$const.MESSAGE[resp.data.message]);
          }
        } else {
          alert(this.$const.MESSAGE.InvalidData);
        }
      } catch (error) {
        alert(error);
      }
      this.saving = false;
    },

    checkValid(installment, paymentAmount, paymentDate) {
      let inValidObject = {};
      if (!paymentAmount) {
        inValidObject["paymentAmount"] = "Vui lòng nhập Tiền trả góp";
      } else {
        if (paymentAmount > installment.Remaining) {
          inValidObject["paymentAmount"] =
            "Tiền trả góp phải nhỏ hơn Dư nợ hiện tại";
        }
      }
      if (!paymentDate) {
        inValidObject["paymentDate"] = "Vui lòng nhập Thời gian";
      }

      return inValidObject;
    },
  },
};
</script>
