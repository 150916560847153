var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CCard',[_c('CCardHeader',[_c('span',{staticClass:"font-weight-bold"},[_c('CIcon',{attrs:{"name":"cilWallet"}}),_vm._v(" DS Trả góp ")],1),_c('div',{staticClass:"d-inline-block float-right align-right"},[_c('CButton',{staticClass:"mr-2",attrs:{"color":"info","size":"sm"},on:{"click":function($event){$event.preventDefault();return (function (e) {
              _vm.showFilter = !_vm.showFilter;
            })($event)}}},[(_vm.showFilter)?_c('CIcon',{attrs:{"name":"cil-chevron-top"}}):_c('CIcon',{attrs:{"name":"cil-chevron-bottom"}})],1)],1)]),_c('CCardBody',[(_vm.showFilter)?_c('filter-box',{attrs:{"agencies":_vm.agencies},on:{"done":function (e) { return _vm.doFilter(e); }}}):_vm._e(),_c('odata-table',{ref:"lstInstallment",attrs:{"url":"odata/OrderInstallment","tableClass":"table-striped","filter":_vm.filter,"expand":"Order($expand=Customer,Visit($select=AgencyId))","sortBy":"UpdatedAt asc","pageSize":_vm.pageSize,"colSetting":{
          Action: {
            display: '#',
            sortable: false,
            style: 'min-width: 50px;',
          },
          Agency:
            _vm.authUser && _vm.authUser.role == _vm.$const.ROLES.SuperAdmin
              ? {
                  field: 'Order/Visit/AgencyId',
                  display: 'Cửa hàng',
                  sortable: false,
                  style: 'min-width: 120px',
                }
              : null,
          OrderNumber: {
            field: 'Order/OrderNumber',
            display: 'Đơn hàng',
            sortable: true,
            style: 'min-width: 150px',
          },
          Installment: {
            field: 'Remaining',
            display: 'Nội dung trả góp',
            sortable: true,
            style: 'min-width: 200px',
          },
          StartDate: {
            field: 'StartDate',
            display: 'Ngày bắt đầu',
            sortable: true,
            style: 'min-width: 120px',
          },
          UpdatedAt: {
            field: 'UpdatedAt',
            display: 'Cập nhật',
            sortable: true,
            style: 'min-width: 120px',
          },
          BillAddress: {
            field: 'Order/Customer/BillAddress',
            display: 'Khách hàng',
            sortable: true,
            style: 'min-width: 200px',
          },
          Status: {
            field: 'Status',
            display: 'Trạng thái',
            sortable: true,
            style: 'min-width: 100px',
          },
        }},scopedSlots:_vm._u([{key:"tbody",fn:function(ref){
        var rows = ref.rows;
return [(_vm.$refs.lstInstallment.loading)?_c('tr',[_c('td',{attrs:{"colspan":"100%"}},[_c('div',{staticClass:"spinner-border m-5",attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Loading...")])])])]):_vm._l((rows),function(row,i){return _c('tr',{key:("tr-" + i)},[_c('td',[_vm._v(_vm._s(i + 1 + (_vm.$refs.lstInstallment.page - 1) * _vm.pageSize)+".")]),(_vm.authUser && _vm.authUser.role == _vm.$const.ROLES.SuperAdmin)?_c('td',[(row.Order && row.Order.Visit)?_c('span',[_vm._v(" "+_vm._s(_vm.renderAgencyName(row.Order.Visit.AgencyId))+" ")]):_vm._e()]):_vm._e(),_c('td',[(row.Order)?_c('a',{staticClass:"text-primary",attrs:{"href":"javascript:","title":("Chi tiết trả góp đơn hàng: #" + (row.Order.OrderNumber))},on:{"click":function (e) {
                    _vm.installmentId = row.Id;
                    _vm.editing = true;
                  }}},[_vm._v(" #"+_vm._s(row.Order.OrderNumber)+" ")]):_vm._e()]),_c('td',[_c('div',[_vm._v(" Trả góp: "+_vm._s(_vm.$func.addCommas(row.PricePer))+" x "+_vm._s(row.Months)+" tháng = "+_vm._s(_vm.$func.addCommas(row.Total))+" ")]),_c('div',[_vm._v("Dư nợ hiện tại: "+_vm._s(_vm.$func.addCommas(row.Remaining)))])]),_c('td',[_vm._v(" "+_vm._s(_vm.$moment .utc(row.StartDate) .local() .format("DD/MM/YYYY"))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.$moment .utc(row.UpdatedAt) .local() .format("DD/MM/YYYY HH:mm"))+" ")]),_c('td',{staticClass:"text-truncate",staticStyle:{"max-width":"200px"}},[_c('span',{attrs:{"title":_vm.renderAddress(row.Order.BillAddress)}},[_vm._v(" "+_vm._s(_vm.renderAddress(row.Order.BillAddress))+" ")])]),_c('td',[_c('CBadge',{attrs:{"color":_vm.getBadge(row.Status)}},[_vm._v(" "+_vm._s(_vm.$const.INSTALLMENT_STATUS_TEXT[row.Status])+" ")])],1)])})]}}])})],1)],1),_c('detail',{attrs:{"editing":_vm.editing,"installmentId":_vm.installmentId},on:{"update:editing":function($event){_vm.editing=$event},"update:installmentId":function($event){_vm.installmentId=$event},"update:installment-id":function($event){_vm.installmentId=$event},"reload":function($event){return _vm.$refs.lstInstallment.loadData()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }