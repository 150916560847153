<template>
  <div>
    <CCard>
      <CCardHeader>
        <span class="font-weight-bold">
          <CIcon name="cilWallet" />
          DS Trả góp
        </span>
        <div class="d-inline-block float-right align-right">
          <CButton
            color="info"
            size="sm"
            class="mr-2"
            @click.prevent="
              (e) => {
                showFilter = !showFilter;
              }
            "
          >
            <CIcon v-if="showFilter" name="cil-chevron-top" />
            <CIcon v-else name="cil-chevron-bottom" />
          </CButton>
        </div>
      </CCardHeader>
      <CCardBody>
        <filter-box
          v-if="showFilter"
          :agencies="agencies"
          @done="(e) => doFilter(e)"
        />
        <odata-table
          ref="lstInstallment"
          url="odata/OrderInstallment"
          tableClass="table-striped"
          :filter="filter"
          :expand="`Order($expand=Customer,Visit($select=AgencyId))`"
          sortBy="UpdatedAt asc"
          :pageSize="pageSize"
          :colSetting="{
            Action: {
              display: '#',
              sortable: false,
              style: 'min-width: 50px;',
            },
            Agency:
              authUser && authUser.role == $const.ROLES.SuperAdmin
                ? {
                    field: 'Order/Visit/AgencyId',
                    display: 'Cửa hàng',
                    sortable: false,
                    style: 'min-width: 120px',
                  }
                : null,
            OrderNumber: {
              field: 'Order/OrderNumber',
              display: 'Đơn hàng',
              sortable: true,
              style: 'min-width: 150px',
            },
            Installment: {
              field: 'Remaining',
              display: 'Nội dung trả góp',
              sortable: true,
              style: 'min-width: 200px',
            },
            StartDate: {
              field: 'StartDate',
              display: 'Ngày bắt đầu',
              sortable: true,
              style: 'min-width: 120px',
            },
            UpdatedAt: {
              field: 'UpdatedAt',
              display: 'Cập nhật',
              sortable: true,
              style: 'min-width: 120px',
            },
            BillAddress: {
              field: 'Order/Customer/BillAddress',
              display: 'Khách hàng',
              sortable: true,
              style: 'min-width: 200px',
            },
            Status: {
              field: 'Status',
              display: 'Trạng thái',
              sortable: true,
              style: 'min-width: 100px',
            },
          }"
        >
          <template v-slot:tbody="{ rows }">
            <tr v-if="$refs.lstInstallment.loading">
              <td colspan="100%">
                <div class="spinner-border m-5" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </td>
            </tr>
            <tr v-else v-for="(row, i) in rows" :key="`tr-${i}`">
              <td>{{ i + 1 + ($refs.lstInstallment.page - 1) * pageSize }}.</td>
              <td v-if="authUser && authUser.role == $const.ROLES.SuperAdmin">
                <span v-if="row.Order && row.Order.Visit">
                  {{ renderAgencyName(row.Order.Visit.AgencyId) }}
                </span>
              </td>
              <td>
                <a
                  v-if="row.Order"
                  href="javascript:"
                  class="text-primary"
                  :title="
                    `Chi tiết trả góp đơn hàng: #${row.Order.OrderNumber}`
                  "
                  @click="
                    (e) => {
                      installmentId = row.Id;
                      editing = true;
                    }
                  "
                >
                  #{{ row.Order.OrderNumber }}
                </a>
              </td>
              <td>
                <div>
                  Trả góp: {{ $func.addCommas(row.PricePer) }} x
                  {{ row.Months }} tháng = {{ $func.addCommas(row.Total) }}
                </div>
                <div>Dư nợ hiện tại: {{ $func.addCommas(row.Remaining) }}</div>
              </td>
              <td>
                {{
                  $moment
                    .utc(row.StartDate)
                    .local()
                    .format("DD/MM/YYYY")
                }}
              </td>
              <td>
                {{
                  $moment
                    .utc(row.UpdatedAt)
                    .local()
                    .format("DD/MM/YYYY HH:mm")
                }}
              </td>
              <td class="text-truncate" style="max-width:200px;">
                <span :title="renderAddress(row.Order.BillAddress)">
                  {{ renderAddress(row.Order.BillAddress) }}
                </span>
              </td>
              <td>
                <CBadge :color="getBadge(row.Status)">
                  {{ $const.INSTALLMENT_STATUS_TEXT[row.Status] }}
                </CBadge>
              </td>
            </tr>
          </template>
        </odata-table>
      </CCardBody>
    </CCard>
    <detail
      :editing.sync="editing"
      :installmentId.sync="installmentId"
      @reload="$refs.lstInstallment.loadData()"
    />
  </div>
</template>

<script>
import odataTable from "@/components/odataTable.vue";
import detail from "./Detail.vue";
import filterBox from "./Filter.vue";

export default {
  name: "Installment",
  components: {
    odataTable,
    detail,
    filterBox,
  },
  data() {
    return {
      pageSize: 10,
      installmentId: 0,
      editing: false,
      showFilter: false,
      filters: null,
      agencies: [],
    };
  },
  computed: {
    filter() {
      let filters = [];

      if (this.filters && this.filters.length) {
        filters = [...this.filters];
      }
      filters.push(
        `(Order/Status ne ${this.$const.ORDER_STATUS.Cancel} or Order/Status ne ${this.$const.ORDER_STATUS.CustCancel})`
      );
      return filters.join(" and ");
    },
    authUser() {
      return this.$user.getters.authUser;
    },
  },
  async mounted() {
    if (this.authUser && this.authUser.role == this.$const.ROLES.SuperAdmin) {
      this.agencies = await this.loadAgencies();
    }
  },
  methods: {
    async loadAgencies() {
      let agencies = [];
      //let active = this.$const.STATUS.Active;
      let resp = await this.$http.get("odata/Agency", {
        params: {
          $top: 1000,
          //$filter: `Status eq ${active}`,
          $select: "Id,Name,Status",
          isAll: true,
        },
      });
      if (resp && resp.status == 200) {
        agencies = resp.data.value.map((_) => {
          return {
            Id: _.Id,
            Name: _.Name,
            Status: _.Status,
          };
        });
      }
      return agencies;
    },

    getBadge(status) {
      switch (status) {
        case this.$const.INSTALLMENT_STATUS.Completed:
          return "success";
        case this.$const.INSTALLMENT_STATUS.InProgress:
          return "warning";
        case this.$const.INSTALLMENT_STATUS.Cancelled:
          return "secondary";
        default:
          "primary";
      }
    },

    renderAddress(address) {
      if (address) {
        let addrObj = JSON.parse(address);
        return (
          addrObj.Title +
          " " +
          Object.keys(addrObj)
            .filter((_) => _ != "Title")
            .map((_) => addrObj[_])
            .filter((_) => _)
            .join(", ")
        );
      }
      return "";
    },

    renderAgencyName(agencyId) {
      let agencies = this.agencies;
      if (agencyId && agencies && agencies.length) {
        let agency = agencies.find((_) => _.Id == agencyId);
        if (agency) {
          return agency.Name;
        }
      }
      return "";
    },

    doFilter(e) {
      if (JSON.stringify(this.filters) != JSON.stringify(e)) {
        this.filters = e;
      } else {
        this.$refs.lstInstallment.loadData();
      }
    },
  },
};
</script>
