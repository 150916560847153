<template>
  <div>
    <div class="table-responsive">
      <table class="table table-hover" :class="tableClass">
        <thead>
          <tr v-if="colSetting" class="bg-light text-dark">
            <template v-for="col in Object.keys(colSetting)">
              <th
                v-if="colSetting[col]"
                :key="`col-${col}`"
                :style="colSetting[col].style"
              >
                <a
                  href="javascript:"
                  v-if="colSetting[col].sortable"
                  @click="sortingBy(colSetting[col].field)"
                  :class="
                    orderBy == `${colSetting[col].field} desc` ||
                    orderBy == `${colSetting[col].field} asc`
                      ? 'text-dark'
                      : 'text-secondary'
                  "
                >
                  <CIcon
                    v-if="orderBy == `${colSetting[col].field} asc`"
                    name="cil-sortAscending"
                    size="sm"
                  />
                  <CIcon v-else name="cil-sortDescending" size="sm" />
                </a>

                <template v-if="$slots[`h${col}`]">
                  <slot :name="`h${col}`" />
                </template>
                <template v-else>{{ colSetting[col].display }}</template>
              </th>
            </template>
          </tr>
        </thead>
        <tbody>
          <template v-if="items && items.length">
            <slot name="tbody" :rows="items"></slot>
          </template>

          <tr v-else>
            <td colspan="100%">
              <div class="text-center my-4">
                <h5>Không tìm thấy kết quả nào!</h5>
              </div>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <slot name="tfoot"></slot>
        </tfoot>
      </table>
    </div>
    <CPagination :activePage.sync="page" :pages="pages" align="center" />
  </div>
</template>

<script>
export default {
  props: [
    "url",
    "filter",
    "select",
    "expand",
    "pageSize",
    "colSetting",
    "sortBy",
    "onData",
    "extraParams",
    "tableClass",
  ],
  data() {
    return {
      items: null,
      total: 0,
      page: 1,
      orderBy: null,
      loading: false,
    };
  },
  computed: {
    pages() {
      let total = this.total;
      let pageSize = this.pageSize;
      return total ? Math.ceil(total / pageSize) : 1;
    },
  },
  mounted() {
    this.orderBy = this.sortBy;
    //this.loadData(1, this.sortBy);
  },
  watch: {
    page() {
      this.loadData();
    },
    sortBy(val) {
      this.orderBy = val;
    },
    orderBy() {
      this.loadData();
    },
    filter() {
      this.loadData();
    },
    extraParams() {
      this.loadData();
    },
    loading(val) {
      this.$emit("loading", val);
    },
  },
  methods: {
    async loadData() {
      this.loading = true;
      try {
        let pageSize = this.pageSize ? this.pageSize : 10;
        let params = {
          $count: true,
          $skip: this.page ? (this.page - 1) * pageSize : 0,
          $top: pageSize,
        };
        if (this.filter) params.$filter = this.filter;
        if (this.select) params.$select = this.select;
        if (this.expand) params.$expand = this.expand;
        if (this.orderBy) params.$orderBy = this.orderBy;
        if (this.extraParams) {
          Object.keys(this.extraParams).forEach((_) => {
            params[_] = this.extraParams[_];
          });
        }

        let resp = await this.$http.get(this.url, {
          params,
        });
        if (resp && resp.status == 200 && resp.data) {
          this.total = resp.data["@odata.count"];
          if (this.onData) {
            this.items = await this.onData(resp.data);
          } else {
            this.items = resp.data.value;
          }
        } else {
          this.items = null;
          this.total = 0;
        }
      } catch (error) {
        //
      }
      this.loading = false;
    },

    sortingBy(col) {
      if (`${col} desc` == this.orderBy) {
        this.orderBy = `${col} asc`;
      } else {
        this.orderBy = `${col} desc`;
      }
    },
  },
};
</script>
