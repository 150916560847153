<template>
  <div>
    <div v-if="isAgencyFilter" class="form-row">
      <CCol md="3">
        <div class="form-group">
          <div class="form-row">
            <label class="col-form-label col-4 text-right">Cửa hàng</label>
            <v-select
              v-model="agencyId"
              :options="
                agencies.filter((_) => _.Status == $const.STATUS.Active)
              "
              :reduce="(p) => p.Id"
              label="Name"
              placeholder="Cửa hàng"
              class="col-8"
            />
          </div>
        </div>
      </CCol>
    </div>
    <div class="form-row">
      <CCol md="3">
        <CInput
          label="Mã đơn"
          placeholder="#Mã đơn"
          v-model="orderNumber"
          :horizontal="{ label: 'col-4 text-right', input: 'col-8' }"
          @keyup.enter="filter"
        />
      </CCol>
      <CCol md="3">
        <div role="group" class="form-group form-row">
          <label class="col-4 text-right col-form-label">
            Từ ngày
          </label>
          <div class="col-8">
            <datetime
              type="date"
              v-model="createdFrom"
              format="dd/MM/yyyy"
              input-class="form-control"
              value-zone="local"
            />
          </div>
        </div>
      </CCol>
      <CCol md="3">
        <div role="group" class="form-group form-row">
          <label class="col-4 text-right col-form-label">
            Đến ngày
          </label>
          <div class="col-8">
            <datetime
              type="date"
              v-model="createdTo"
              format="dd/MM/yyyy"
              input-class="form-control"
              value-zone="local"
            />
          </div>
        </div>
      </CCol>
      <CCol md="3">
        <CSelect
          label="Trạng thái"
          placeholder="Trạng thái"
          :value.sync="installmentStatus"
          :options="[
            { value: null, label: 'Tất cả' },
            ...Object.keys($const.INSTALLMENT_STATUS_TEXT).map((_) => {
              return { value: _, label: $const.INSTALLMENT_STATUS_TEXT[_] };
            }),
          ]"
          :horizontal="{ label: 'col-4 text-right', input: 'col-8' }"
        />
      </CCol>
      <CCol md="3">
        <CInput
          label="Tên KH"
          placeholder="Tên KH"
          v-model="customerName"
          :horizontal="{ label: 'col-4 text-right', input: 'col-8' }"
          @keyup.enter="filter"
        />
      </CCol>
      <CCol md="3">
        <CInput
          label="Số ĐT"
          placeholder="Số ĐT"
          v-model="customerPhone"
          :horizontal="{ label: 'col-4 text-right', input: 'col-8' }"
          @keyup.enter="filter"
        />
      </CCol>
      <CCol md="3" class="text-center">
        <CButton color="primary" class="mr-2 mb-3" @click="filter">
          <CIcon name="cil-search" custom-classes="c-icon m-0" />
          Tìm kiếm
        </CButton>

        <CButton color="secondary" class="mb-3" @click="clear">
          <CIcon name="cil-x-circle" custom-classes="c-icon m-0" />
          Đặt lại
        </CButton>
      </CCol>
    </div>
  </div>
</template>

<script>
import { Datetime } from "vue-datetime";
// You need a specific loader for CSS files
import "vue-datetime/dist/vue-datetime.css";

export default {
  props: ["agencies"],
  components: {
    datetime: Datetime,
  },
  data() {
    return {
      orderNumber: null,
      createdFrom: null,
      createdTo: null,
      installmentStatus: null,
      agencyId: null,
      customerName: null,
      customerPhone: null,
    };
  },
  computed: {
    isAgencyFilter() {
      let authUser = this.$user.getters.authUser;
      return authUser
        ? authUser.role == this.$const.ROLES.SuperAdmin ||
            (authUser.role == this.$const.ROLES.Owner &&
              authUser.agencyIds &&
              authUser.agencyIds.includes(","))
        : false;
    },
  },
  methods: {
    filter() {
      let filters = [];
      this.orderNumber &&
        filters.push(`Order/OrderNumber eq '${this.orderNumber}'`);
      if (this.createdFrom) {
        let createdFrom = this.$moment(this.createdFrom).toISOString();
        filters.push(`StartDate ge ${createdFrom}`);
      }
      if (this.createdTo) {
        let createdTo = this.$moment(this.createdTo)
          .add(1, "days")
          .toISOString();
        filters.push(`StartDate lt ${createdTo}`);
      }
      this.installmentStatus &&
        filters.push(`Status eq ${this.installmentStatus}`);
      this.agencyId && filters.push(`Order/Visit/AgencyId eq ${this.agencyId}`);
      this.customerName &&
        filters.push(`contains(Order/Customer/Name, '${this.customerName}')`);
      this.customerPhone &&
        filters.push(
          `contains(Order/Customer/Mobile, '${this.customerPhone}')`
        );

      this.$emit("done", filters);
    },

    clear() {
      this.orderNumber = null;
      this.createdFrom = null;
      this.createdTo = null;
      this.installmentStatus = null;
      this.agencyId = null;
      this.customerName = null;
      this.customerPhone = null;
    },
  },
};
</script>
